import { toTitleCase } from "../../../utils/toTitleCase";

export const cleanDispositionString = (disposition) => {
  disposition = disposition.toLowerCase();
  if (disposition.startsWith("{")) {
    disposition = disposition.substring(1);
  }
  if (disposition.endsWith("}")) {
    disposition = disposition.substring(0, disposition.length - 1);
  }

  if (disposition === "dismissed (w/o prej)") {
    disposition = "Dismissed without prejudice";
  } else if (disposition === "dismissed w/ prejudi") {
    disposition = "Dismissed with prejudice";
  }
  return toTitleCase(disposition);
};
