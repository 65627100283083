import { useLocation, useNavigate } from "react-router-dom";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { getCaseCount } from "../../../api/secureApi";
import { primaryButton } from "../../../constants/classnames";
import { STATE_LIST } from "../../../constants/usStates";
export function RecordsReady() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [caseCount, setCaseCount] = useState(0);
  const [recordOrRecords, setRecordOrRecords] = useState("records");
  // eslint-disable-next-line no-console
  console.log("state in OpenCases", state);

  useEffect(() => {
    getCaseCount()
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log("response", response);
        // eslint-disable-next-line no-console
        console.log("response", response.count);
        if (response && response.count) {
          setCaseCount(response.count);
          if (response.count === 1) {
            setRecordOrRecords("record");
          } else {
            setRecordOrRecords("records");
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("could not get case count", error);
      });
  }, [state]);
  const toPaymentClick = () => {
    const newState = {
      ...state,
      amount: "15",
    };
    navigate("/register/payment", { state: newState });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="mb-6 font-serif text-xl">your records are just around the corner</h2>
          <h3 className="font-sans font-bold leading-5">
            {/*{if 0, omit this message}*/}
            {caseCount > 0 &&
              `We have found ${caseCount} ${recordOrRecords} matching the name and date of birth you provided`}
          </h3>
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-4 flex max-w-3xl flex-col px-4">
          <p className="mb-4">
            This eligibility tool will show your <b>{STATE_LIST[state.us_state]}</b> records and
            tell you if they may be eligible.
          </p>
          <p className="mb-6">
            For your privacy, we want to make sure it's you before we display any records.
          </p>
          <div className="relative rounded-md bg-pink-100 px-6 py-4">
            <div className="absolute -top-3 flex h-6 w-6 items-center justify-center rounded-full bg-pink-100">
              <ShieldCheckIcon className="h-4 text-rasapink" />
            </div>
            <p>
              If the name you've provided does not match the name on the credit card you use, we
              will ask for the last 4 digits of your social security number to verify your identify.
            </p>
          </div>
        </div>
      </section>
      <section id="button" className="mb-6">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <div className="mb-6 overflow-hidden rounded-md border-2 border-gray-200 px-6 py-4">
            <h3 className="mb-3 text-center font-sans text-xl font-bold">check eligibility: $15</h3>
            <button type="button" className={primaryButton} onClick={toPaymentClick}>
              proceed to payment
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
