import { useLocation, useNavigate } from "react-router-dom";
import { primaryButton, secondaryButton } from "../../../../constants/classnames";

export function DiscountQuestions() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in discount questions", state);

  const handleToDiscountQuestionsClick = () => {
    navigate("/register/employed", { state });
  };

  const handleToPaymentClick = () => {
    navigate("/register/records-ready", { state });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className="font-sans text-xl font-bold">
            will you answer 11 short questions for a $10 discount?
          </h3>
        </div>
      </section>
      <section id="bodyText">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <div className="mb-4">
            We are continually trying to build partnerships to lower the cost of record clearance
            services. Gathering data about how a record can impact a person's housing, employment,
            and education can help us make the case for these partnerships and clear more records.
          </div>
          <div className="mb-6">
            In addition, we want to make you aware of potential fair chance hiring opportunities,
            scholarships, and other benefits that could help a person with a record improve their
            life.
          </div>
          <div className="mb-6">
            If you are willing to help us, we will provide a $10 discount on our eligibility tool.
          </div>
        </div>
      </section>
      <section id="button" className="mb-6">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
          <div className="mb-6 overflow-hidden rounded-md border-2 border-gray-200 px-6 py-4">
            {/*<h3 className="mb-3 text-center font-sans text-xl font-bold">Check Eligibility: $15</h3>*/}
            <button
              type="button"
              onClick={handleToDiscountQuestionsClick}
              className={primaryButton}
            >
              get $10 discount
            </button>
            <div className="mt-2">
              {/*<h3 className="mb-3 text-center font-sans text-xl font-bold">Check Eligibility: $15</h3>*/}
              <button type="button" onClick={handleToPaymentClick} className={secondaryButton}>
                proceed to $15 payment
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
